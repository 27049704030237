<template>
  <v-app>
    <Header :color="color" :flat="flat" />
    <v-main class="pt-0">
      <slot />
    </v-main>
    <Footer />
    <v-slide-y-transition>
      <v-btn
        v-show="scrollButton"
        dark
        position="fixed"
        icon="mdi-chevron-up"
        aria-label="to top"
        color="secondary"
        class="mb-12 scrollButton"
        @click="toTop"
      />
    </v-slide-y-transition>
  </v-app>
</template>

<script>
export default {
  data () {
    return {
      scrollButton: false,
      color: 'transparent',
      flat: true
    }
  },
   watch: {
    scrollButton (value) {
      if (value) {
        this.color = 'secondary'
        this.flat = false
      } else {
        this.color = 'transparent'
        this.flat = true
      }
    }
  },
  methods: {
    handleScroll () {
      if (window.pageYOffset > 100) {
        this.scrollButton = true;
      } else {
        this.scrollButton = false;
      }
    },
    toTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped>
  .v-main {
    background-image: url("/img/bgMain.webp");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }

  .scrollButton {
    bottom: 20px;
    right: 20px;
  }
</style>
