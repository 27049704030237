<template>
  <div>
    <!-- mobile nav bar -->
    <v-navigation-drawer v-model="drawer" app temporary width="275">
      <v-list>
        <v-list-item>
          <nuxt-img
            src="/img/orthofresh-logo.webp"
            format="webp"
            quality="100"
            :placeholder="[250, 110, 50, 1]"
            width="250"
            alt="OrthoFresh Logo"
          />
          <span class="font-weight-light text-accent">
            A product of <br /> BRABEON Medical Corporation
          </span>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="([text, link], i) in items"
          :key="i"
          link
          @click="goToLink(link)"
          class="text-h3 font-weight-bold text-primary"
          :title="text"
          :value="text"
        />
      </v-list>
    </v-navigation-drawer>

    <!-- main app bar -->
    <v-app-bar
      :color="color"
      :flat="flat"
      :dark="!flat"
      height="80"
      class="px-8"
      :class="{ expand: flat }"
    >
      <v-app-bar-title>
        <v-row>
          <v-col cols="12" class="d-flex align-center">
            <div class="text-center">
              <nuxt-img
                src="/img/orthofresh-logo.webp"
                format="webp"
                quality="100"
                :placeholder="[250, 110, 50, 1]"
                width="250"
                alt="OrthoFresh Logo"
                class="mb-n10 mt-n8"
              />
              <v-row class="pa-0 ma-0">
                <span v-if="flat" class="text-accent text-overline">
                  A Product of Braebon Medical Corporation
                </span>
                <span v-else class="font-weight-light text-accent overline" />
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-app-bar-title>

      <template v-slot:append>
        <v-btn
          v-if="isXs"
          class="mr-4"
          color="primary"
          icon="mdi-menu"
          size="large"
          variant="outlined"
          aria-label="Menu"
          @click.stop="drawer = !drawer"
        />

        <div v-else>
          <v-btn text :color="flat ? 'primary' : 'white'" class="headerText font-weight-bold" aria-label="Home Button" @click="goToLink('hero')">
            <span>Home</span>
          </v-btn>
          <v-btn text :color="flat ? 'primary' : 'white'" class="headerText font-weight-bold" aria-label="Availability Button" @click="goToLink('features')">
            <span>Availability</span>
          </v-btn>
          <v-btn text :color="flat ? 'primary' : 'white'" class="headerText font-weight-bold" aria-label="About Button" @click="goToLink('about')">
            <span>About</span>
          </v-btn>
        </div>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'transparent'
    },
    flat: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      drawer: null,
      isXs: false,
      items: [
        ['Home', 'hero'],
        ['Availability', 'features'],
        ['About', 'about']
      ]
    }
  },
  watch: {
    isXs (value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false
        }
      }
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize () {
      this.isXs = window.innerWidth < 850
    }
  }
}
</script>

<script setup>
  function goToLink (link) {
    const element = document.getElementById(link);
    element.scrollIntoView({ behavior: "smooth" });  }
</script>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 98px !important;
  padding-top: 10px;
}

.headerText {
  font-size: 14px;
  /* text-shadow: 0px 0px 0.5px black; */
}
</style>
