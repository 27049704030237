<template>
  <v-footer color="secondary">
    <v-card color="secondary" variant="flat" class="text-white text-center">
      <v-card-text class="text-white">
        <v-row class="px-xs-0 mx-xs-0 px-lg-10 mx-lg-10 px-xl-12 mx-xl-12 mt-4">
          <v-col cols="12" md="3" class="text-center dark">
            <span style="color:#ffffff">&copy; {{ new Date().getFullYear() }} BRAEBON Medical Corporation</span>
          </v-col>
          <v-col cols="12" md="6" class="text-center">
            <span style="color:#ffffff">Tel 1.613.831.6690 or North America Toll-Free 1.888.462.4841</span>
          </v-col>
          <v-col cols="12" md="3" class="text-center">
            <span class="footerText">
              <a href="/documents/BRAEBONPrivacy.pdf" target="_blank" style="cursor:pointer;color:#ffffff;"> Privacy</a>
            </span>
          </v-col>
        </v-row>

        <!-- socials -->
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn
              icon="mdi-instagram"
              color="primary"
              variant="plain"
              size="large"
              href="https://www.instagram.com/orthofresh_cleaner"
              target="_blank"
              aria-label="Instagram"
            />
            <v-btn
              icon="mdi-facebook"
              color="primary"
              variant="plain"
              size="large"
              href="https://www.facebook.com/profile.php?id=100084691556157"
              target="_blank"
              aria-label="Facebook"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style scoped>
.v-card {
  width: 100%;
}
</style>
